.card {
  margin: 3rem;
}

.card-img {
  width: 28rem;
  height: 18.5rem;
}

.card-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
}

.card-content {
  background-color: #edefee;
  width: 28rem;
  height: 26rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.card-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 2rem;
}

.card-name {
  font-family: markazi;
  font-weight: 600;
  font-size: 2.2rem;
}

.card-price {
  color: #ee9972;
  font-weight: bold;
}

.card-desc {
  color: #495e57;
  text-align: left;
  padding: 0 2rem;
}

.delivery {
  display: flex;
  padding: 2rem 2rem;
}

.card-delivery {
  padding-right: 1rem;
}

@media screen and (min-width: 300px) and (max-width: 700px) {
  .card {
    margin-bottom: 2.5rem;
  }

  .card-content {
    border-radius: 0 0 16px 16px;
  }
}
