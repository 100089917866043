.about {
  display: flex;
  align-items: center;
  /* width: 100%; */
  height: 55rem;
  padding: 0 20rem;
}

.paragraph {
  padding-top: 1rem;
}

.image-sec {
  position: relative;
  right: 22rem;
  top: 13rem;
}

.about-text {
  text-align: left;
  padding-right: 69rem;
  position: relative;
  top: 0;
  left: 25rem;
  bottom: 3rem;
}

.about-image {
  width: 26.7rem;
  height: 33.6rem;
}

.about-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img2 {
  position: relative;
  top: -25rem;
  z-index: -1;
  right: 10rem;
}

@media screen and (min-width: 300px) and (max-width: 700px) {
  .about {
    flex-direction: column;
    height: 65rem;
    padding: 3rem 0;
  }

  .heading {
    font-size: 4rem;
    padding-left: 1.7rem;
  }

  .city {
    padding-left: 1.7rem;
  }

  .about-text {
    padding: 0px 25px;
    left: 0;
  }

  .about-image {
    width: 16.7rem;
    height: 23.6rem;
  }

  .image-sec {
    left: 4.5rem;
    top: 5rem;
  }
}
