.navigation {
  height: 10rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.logo {
  display: inline-block;
  height: 5rem;
}

.list {
  display: inline-block;
  margin-left: 2.3rem;
}

.list > :first-child {
  color: black;
}

@media screen and (min-width: 300px) and (max-width: 700px) {
  .navigation {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .logo {
    height: 4rem;
  }
}
