.hero {
  background-color: #f4ce14;
  height: 40rem;
  width: 100%;
  text-align: left;
  margin-bottom: 9rem;
}

.hero-content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0 10rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 6rem 3rem;
}

.text {
  margin-bottom: 3rem;
}

.heading {
  font-size: 5rem;
}

.city {
  font-family: markazi;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.btn {
  height: 6rem;
  width: 20rem;
  color: white;
  background-color: black;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}

.btn-m {
  margin: 0 0 0 8rem;
}

.hero-img {
  width: 37.5rem;
  height: 44rem;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  margin-top: 2.5rem;
}

@media screen and (min-width: 300px) and (max-width: 700px) {
  .hero {
    height: auto;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .hero-img {
    width: auto;
    height: auto;
    margin-top: 1rem;
  }

  .content {
    padding: 1rem 0;
    text-align: center;
    align-items: center;
  }

  .hero-content {
    align-items: center;
    flex-direction: column-reverse;
    padding: 2rem 0;
  }

  .img {
    width: 25rem;
    height: auto;
    border-radius: 12px;
    margin: 0 auto;
  }

  .paragraph {
    padding: 0 2rem;
  }
}

.btn:hover {
  background-color: rgb(27, 27, 27);
}
