@import url("https://fonts.googleapis.com/css2?family=Karla&family=Markazi+Text:wght@500&display=swap");

* {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  font-family: "Karla", "Markazi Text", serif;
  font-size: 1.8rem;
  text-align: center;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}
