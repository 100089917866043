.review {
  background-color: #ffffff;
  width: 20rem;
  height: 19rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 1rem;
}

.review-container {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  justify-content: flex-end;
}

.review-container > p {
  font-weight: 600;
}

.review-img {
  margin: 0 1rem 5px;
  width: 4.7rem;
  height: 4.6rem;
}

.review-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.para {
  padding: 0 1.5rem 0.5rem;
  font-style: italic;
}

@media screen and (min-width: 300px) and (max-width: 700px) {
  .review {
    margin: 1.2rem 0;
  }
}
