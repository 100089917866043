footer {
  background: #d9d9d9;
  width: 100%;
  height: 30rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  text-align: left;
  margin: 0 auto;
}

.foot-logo {
  width: 11rem;
  height: fit-content;
  margin: 0 8rem;
}

.foot-lists {
  margin: 2rem 8rem 0;
}

.foot-heading {
  margin-bottom: 1rem;
  color: #495e57;
}

a {
  color: black;
}

@media screen and (min-width: 300px) and (max-width: 900px) {
  footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    justify-items: start;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    padding: 3rem 0;
  }

  .foot-heading {
    margin: 1rem 0;
  }

  .foot-logo {
    width: 8rem;
    margin: 0 3.5rem;
  }

  .foot-lists {
    margin: 0 3rem;
  }
}
