.testimonial {
  width: 100%;
  height: 50rem;
  background-color: #f4ce14;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.testimonial-heading {
  margin-bottom: 5rem;
}

.reviews {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 40rem;
  margin-bottom: 5rem;
}

@media screen and (min-width: 300px) and (max-width: 700px) {
  .testimonial {
    height: auto;
  }

  .reviews {
    padding: 0 2rem;
    flex-wrap: wrap;
  }

  .testimonial-heading {
    margin: 3rem 0;
  }
}
