form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 70rem;
  font-size: 2rem;
  height: 75vh;
}

input,
select {
  font-family: "Karla", "Markazi Text", serif;
  font-size: 1.8rem;
  display: block;
  border: 1px solid black;
  border-radius: 3px;
  height: 2.5rem;
  width: 55rem;
  margin: 0.4rem 0;
}

label {
  text-align: left;
  position: relative;
  width: 100%;
}

input[type="text"],
input[type="email"],
input[type="date"],
input[type="time"],
input[type="number"],
input[type="password"],
select {
  padding: 1px 1rem;
}

#res-time,
#res-occasion {
  width: 58rem;
  height: 2.8rem;
}

.form-group {
  display: flex;
  align-items: center;
}

.form-input > p,
.login-para {
  margin-bottom: 3rem;
}

.btn[type="submit"] {
  margin-top: 2rem;
  height: 4rem;
  width: 20rem;
}

@media screen and (min-width: 300px) and (max-width: 700px) {
  form {
    height: auto;
    margin: 2rem 5rem 0;
  }
  input {
    width: 100%;
  }
  #res-time,
  #res-occasion {
    width: 100%;
  }
  .form-group {
    flex-direction: column;
    margin: 1rem 0;
  }
  input[type="text"],
  input[type="email"],
  input[type="date"],
  input[type="time"],
  input[type="number"],
  input[type="password"],
  select {
    padding: 0;
  }

  .btn[type="submit"] {
    margin-top: 1rem;
  }
}
